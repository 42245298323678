<template>
  <div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="warning"
      @click="previewURL"
    >
      <feather-icon
        icon="BookIcon"
        class="mr-50"
      />
      <span class="align-middle">Manual</span>
    </b-button>

  </div>
</template>

<script>
import 'viewerjs/dist/viewer.css'
import { api as viewerApi } from 'v-viewer'

export default {
  data() {
    return {
      // eslint-disable-next-line global-require
      imageManual: [require('@/assets/images/picture/HIE2-1.jpg')],
    }
  },
  created() {
    this.$http({
      url: 'http://192.168.100.56/api/PreVisit/CustomerVisitDetail/0/3500400281899',
      method: 'GET',
      data: {
        // eslint-disable-next-line no-undef
        Docs: Doc[0].Doc,
      },
    })
  },
  methods: {
    previewURL() {
      const $viewer = viewerApi({
        options: {
          toolbar: true,
          initialViewIndex: 0,
        },
        images: this.imageManual,
      })
      // console.log($viewer)
    },
  },
}
</script>

<style>

</style>
